import {Modal} from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import {useTranslation} from "react-i18next";
import LoadingAnimation from '@/assets/lottie/loading-gray.json';

interface ILoadingModalProps {
    show: boolean;
}

export default function LoadingModal({show}: ILoadingModalProps) {
    const {t} = useTranslation();

    return (
        <Modal
            show={show}
            fullscreen
            style={{
                background: 'rgba(0, 0, 0, 0.2)',
                backdropFilter: 'blur(5px)',
                zIndex: 10004
            }}
            centered
        >
            <Modal.Body
                className="d-flex justify-content-center align-items-center"
                style={{background: 'transparent'}}
            >
                <div
                    className="bg-white rounded-4 p-5 d-flex flex-column align-items-center"
                    style={{maxWidth: '300px'}}
                >
                    <Lottie
                        play
                        loop
                        animationData={LoadingAnimation}
                        style={{width: "120px", height: "120px"}}
                    />
                    <p className="mt-3 text-primary fw-bold">{t('scheduling')}</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}