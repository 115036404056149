import {Button, ButtonToolbar, Modal, Stack} from 'react-bootstrap';
import style from '@/styles/ScheduleBar.module.css'
import Calendar from "react-calendar";
import '@/styles/Calendar.css';
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Lottie from 'react-lottie-player';
import {useSchedule} from "@/services";
import {useTranslation} from "react-i18next";
import {AiOutlineCalendar, AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import Picker from 'react-mobile-picker'
import SuccessAnimation from '@/assets/lottie/confirmation.json';
import ErrorAnimation from '@/assets/lottie/error.json';
import {useNavigate} from "react-router-dom";
import LoadingModal from './LoadingModal';

interface IConfirmModalProps {
    selectedDate?: Date;
    show: boolean;
    close: () => any;
    confirm: () => any;
}

interface IResultModalProps {
    success?: boolean;
    close: () => any;
}

function ResultModal({success, close}: IResultModalProps) {
    const navigate = useNavigate()
    return <Modal style={{
        zIndex: 10003
    }} show={success !== undefined} fullscreen>
        <Lottie
            loop={false}
            onComplete={() => {
                if (success === true) {
                    navigate('/my-schedule', {state: {forceServer: true}})
                } else if (success === false) {
                    close()
                }
            }}
            play
            animationData={success ? SuccessAnimation : ErrorAnimation}
        />
    </Modal>
}

function ConfirmModal({selectedDate, show, close, confirm}: IConfirmModalProps) {
    const {t} = useTranslation();
    const formatter = Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        hour12: false
    }).format
    return <Modal style={{
        zIndex: 10003
    }} fullscreen onHide={close} show={show}>
        <Modal.Header>
            <Modal.Title>{t('select_modal_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                !!selectedDate ?
                    `${t('select_modal_body')} ${formatter(selectedDate)}?` :
                    t('no_date_selected')
            }
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
            <ButtonToolbar>
                <Button className={style.modal_button} onClick={close} variant={"outline-primary"}>
                    <AiOutlineClose/>
                    {t('cancel')}
                </Button>
                {
                    !!selectedDate &&
                    <Button className={style.modal_button} onClick={confirm} variant='primary'>
                        <AiOutlineCheck/>
                        {t('confirm')}
                    </Button>
                }
            </ButtonToolbar>
        </Modal.Footer>
    </Modal>
}

export default function ScheduleBar() {
    const formatter = Intl.DateTimeFormat('pt-BR', {
        weekday: "short"
    }).format;

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [hoursList, setHoursList] = useState<{ hourList: string[] }>({hourList: []});
    const [valueGroup, setValueGroup] = useState({hourList: ""});
    const [availableDates, setAvailableDates] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateString, setSelectedDateString] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirmationStatus, setConfirmationStatus] = useState<undefined | boolean>();
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation();
    const {listHourly, schedule} = useSchedule();
    const maxDate = useMemo(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        return now;
    }, []);

    const updateHours = useCallback((date: Date) => {
        const hourFormatter = Intl.DateTimeFormat('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format;

        listHourly(date).then(dates => {
            const formattedHours = dates.map(d => hourFormatter(new Date(d)));
            setHoursList({
                hourList: formattedHours
            });
            setAvailableDates(dates);
        }).catch((err) => {
            console.error(err);
        });
    }, [listHourly]);

    const handleChange = useCallback((name: string, value: string) => {
        if (!value) {
            return;
        }
        setValueGroup({
            hourList: value
        });
    }, []);

    const handleClickDay = useCallback((day: Date) => {
        day.setHours(0, 0, 0, 0);
        setSelectedDate(day);
        updateHours(day);
    }, [updateHours]);

    const handleConfirm = useCallback(() => {
        setIsLoading(true);
        setShowModal(false);
        schedule(selectedDateString)
            .then(() => setConfirmationStatus(true))
            .catch(() => setConfirmationStatus(false))
            .finally(() => setIsLoading(false));
    }, [schedule, selectedDateString]);

    const handleButtonClick = useCallback(() => {
        const selectedTimeString = valueGroup?.hourList || hoursList.hourList[0];
        const selectedDateIndex = hoursList.hourList.indexOf(selectedTimeString);
        const originalDateString = availableDates[selectedDateIndex];

        setSelectedDateString(originalDateString);
        setSelectedDate(new Date(originalDateString));
        setShowModal(true);
    }, [hoursList.hourList, valueGroup.hourList, availableDates]);

    useEffect(() => {
        updateHours(new Date());
    }, [updateHours]);

    return <Stack className="content">
        <h1 className={style.title}>{t('schedule_title')}</h1>
        <Calendar
            formatShortWeekday={(locale, date) => {
                return (formatter(date) as string)[0].toUpperCase()
            }}
            defaultValue={new Date()}
            minDate={new Date()}
            goToRangeStartOnSelect={false}
            maxDetail='month'
            minDetail='decade'
            returnValue='start'
            showNavigation={true}
            showNeighboringMonth={true}
            maxDate={maxDate}
            onClickDay={handleClickDay}
        />
        <Picker
            wheel={"normal"}
            valueGroups={valueGroup}
            optionGroups={hoursList}
            height={150}
            onChange={handleChange}
        />
        <Button
            ref={buttonRef}
            onClick={handleButtonClick}
            className={style.button}
            disabled={isLoading}
        >
            <AiOutlineCalendar />
            <span>{t('schedule')}</span>
        </Button>

        <LoadingModal show={isLoading} />
        <ConfirmModal
            confirm={handleConfirm}
            close={() => setShowModal(false)}
            show={showModal}
            selectedDate={selectedDate}
        />
        <ResultModal
            close={() => setConfirmationStatus(undefined)}
            success={confirmationStatus}
        />
    </Stack>
}