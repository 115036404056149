import {useAuthentication} from '@/services';
import {FormEvent, useCallback, useMemo, useRef, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import Lottie from 'react-lottie-player';
import style from '@/styles/LoginForm.module.css';
import LoadingAnimation from '@/assets/lottie/loading-gray.json'

interface ILoginFormProps {
    onSuccess(): void;
}

export default function LoginForm({onSuccess}: ILoginFormProps) {
    const passwordLinkRef = useRef<null | HTMLAnchorElement>(null);
    const buttonRef = useRef<null | HTMLButtonElement>(null)
    const emailRef = useRef<null | HTMLInputElement>(null);
    const checkBoxRef = useRef<null | HTMLInputElement>(null);
    const passRef = useRef<null | HTMLInputElement>(null)
    const [hasRequested, setHasRequested] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPassword, setIsPassword] = useState<boolean>(false);


    const {SignIn, Request} = useAuthentication();
    const {t} = useTranslation()
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [passwordInvalid, setPasswordInvalid] = useState(false)

    const isShowPasswordLink = useMemo(()=>{
            //const isChecked = checkBoxRef.current?.checked;
            //const email = emailRef.current?.value;
            
            return (!hasRequested)? {display:'block'} :{display:'none'}
    }, [hasRequested]);


    const renderField = useCallback(() => {
        return <>
            <Form.Group hidden={hasRequested} controlId='email'>
                <Form.Label>{t('username_input')}</Form.Label>
                <Form.Control
                    type='number'
                    placeholder={t('username_placeholder') || ''}
                    isInvalid={emailInvalid}
                    ref={emailRef}
                />
                <Form.Control.Feedback hidden={hasRequested} type='invalid'>
                    {t('username_error')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                style={{
                    marginBottom:"1em"
                }}
                hidden={!hasRequested}>
                <Form.Label>{isPassword?t('password_input'):t('code_input')}</Form.Label>
                <Form.Control
                    type={isPassword?'password':'number'}
                    placeholder={(isPassword?t('password_placeholder'):t('code_placeholder'))||''}
                    isInvalid={passwordInvalid}
                    ref={passRef}
                />
                <Form.Control.Feedback hidden={!hasRequested} type='invalid'>
                    {t('password_error')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group hidden={hasRequested}>
                <Form.Check
                    ref={checkBoxRef}
                    type='checkbox'
                    className={style.check}
                    label={<>
                    <span>
                            {t('terms_accept')}
                        </span>
                        {' '}
                        <a
                            rel='noreferrer'
                            href={'https://salvosaude.com.br/termos-de-uso'}
                            target='_blank'
                        >
                            {t('terms_conditions')}
                        </a>
                        {` ${t("terms_divider")} `}
                        <a
                            rel='noreferrer'
                            href={'https://salvosaude.com.br/politica-de-privacidade'}
                            target='_blank'
                        >
                            {t('terms_privacy')}
                        </a>
                    </>}
                    onChange={(event) => {
                        if (event.currentTarget.checked) {
                            passwordLinkRef.current?.setAttribute('style', 'display:block');
                            buttonRef.current?.removeAttribute('disabled')
                        } else {
                            passwordLinkRef.current?.setAttribute('style', 'display:none');
                            buttonRef.current?.setAttribute('disabled', 'true')
                        }
                    }}
                />
            </Form.Group>
        </>
    }, [emailInvalid, hasRequested, isPassword, passwordInvalid, t])

    const requestCode = useCallback((event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                event.stopPropagation()
                let email = emailRef.current?.value;
                let pass = passRef.current?.value;
                if (!email) {
                    setEmailInvalid(true)
                    return
                }
                
                if (!hasRequested) {
                    setIsLoading(true)
                    buttonRef.current?.setAttribute('disabled', "true")
                    Request(email).then(() => {
                        setHasRequested(true)
                    }).catch((err) => {
                        console.error(err)
                        setEmailInvalid(true)
                    }).finally(()=>{
                        setIsLoading(false)
                        buttonRef.current?.removeAttribute('disabled')
                    })
                    return
                }
                if (!pass) {
                    setPasswordInvalid(true)
                    return
                }
                SignIn(email, pass, !isPassword).then(() => {
                    onSuccess();
                }).catch((error) => {
                    
                    setPasswordInvalid(true)
                    setHasRequested(false)
                });
                return false;
            }, [Request, SignIn, hasRequested, isPassword, onSuccess]
        );


    return <>
        <Form onSubmit={requestCode} className={style.form}>
            {renderField()}
            <Button
                variant='primary'
                type='submit'
                ref={buttonRef}
                disabled
            >
                <Lottie
                    hidden={!isLoading}
                    play
                    loop
                    animationData={
                        LoadingAnimation
                    }
                    style={{
                        height: "1.5em"
                    }}
                />
                <span hidden={isLoading}>
                { hasRequested? (isPassword?t('password_confirm'):t('code_confirm')): t('login_submit')}
                </span>
            </Button>
            <Form.Group>
                <a
                    className={style.password}
                    style={isShowPasswordLink}
                    ref={passwordLinkRef}
                    onClick={(event)=>{
                    event.preventDefault();
                    let email = emailRef.current?.value;
                    if(!email) return false
                    setHasRequested(true);
                    setIsPassword(true);
                    buttonRef.current?.removeAttribute('disabled')
                    passwordLinkRef.current?.setAttribute('style', 'display:none')
                    return false;
                }} href="#" rel={"noopener"}>
                    {t('password-signin')}
                </a>
            </Form.Group>
        </Form>
    </>
}